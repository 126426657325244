<template>
  <section class="railroad">
    <general-second :name="page.pageName" :descript="page.description"></general-second>
    <!--<railroad-equip></railroad-equip>
    <fire-safety></fire-safety>-->
    <h2 class="center" style="margin: 100px 0px;">Site is under construction</h2>
  </section>
</template>

<script>
/* import FireSafety from '@/components/railroad/FireSafety'
import RailroadEquip from '@/components/railroad/RailroadEquip' */
import GeneralSecond from '@/components/GeneralSecond'
export default {
  name: 'Railroad',
  components: {
    GeneralSecond
    /* RailroadEquip,
    FireSafety */
  },
  data () {
    return {
      page: {
        pageName: 'Railroad',
        description: ''
      }
    }
  },
  mounted () {
    if (Object.keys(this.$route.params).length > 0) {
      location.href = `${location.href}#${this.$route.params.id}`
    }
  },
  metaInfo: {
    title: 'Railroad',
    titleTemplate: '%s - VERTEX GmbH',
    htmlAttrs: {
      lang: 'en',
      amp: true
    }
  }
}
</script>

<style>

</style>
